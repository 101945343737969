/* eslint-disable import/prefer-default-export */
import * as React from "react";
import {
  Icon,
  ImageContext,
  LinkContext,
  Listbox,
  LoadingIndicator,
  Swatch,
} from "@components";
import classNames from "classnames";
import { useRouter } from "next/router";
import { useRunOnVisibleOnce } from "src/hooks/useIntersectionObserver";

import useLogger from "../../../../hooks/useLogger";
import { CrossingMindsContext } from "../../../context/CrossingMindsContext";
import usePriceFormatter from "../../../hooks/usePriceFormatter";
import type {
  RecommendedProduct,
  RecommendedProductOption,
} from "../../../hooks/useRecommendedProductsQuery";
import { useSelectedVariant } from "../../../hooks/useSelectedVariant";
import useTypedMutation from "../../../hooks/useTypedMutation";
import { useTypedQuery } from "../../../hooks/useTypedQuery";
import { CartMutation } from "../../../queries/data/cartData";
import { LocaleCode } from "../../../util/locale";
import makeProductUrl from "../../../util/makeProductUrl";
import { toKeyValue } from "../../../util/toKeyValue";
import { LineItem } from "../../Cart/components/LineItem";
import cartUtils from "../../Cart/utils";
import { formatOptionValues } from "../../util";
import selectors, { analytics } from "../selectors";
/**
 * Only one option picker should be shown in the page section carousel.
 * Which option we should show varies by product.
 */
export function getDisplayedOptions(
  options: RecommendedProductCardProps["product"]["options"]
) {
  // TODO: Specify which options get displayed in Contentful and don't do this
  const swatchOptions = options.filter(
    (option) => option.type === "swatch" && option.values.length > 1
  );
  return swatchOptions.slice(0, 1);
}

// TODO: extend ProductOptionPicker so that we can use it in place of the following
function ProductOptions({
  onChange,
  option,
  selectedValue,
}: {
  onChange: (value: string) => void;
  option: RecommendedProductOption;
  selectedValue?: string;
}) {
  const hasSwatch = option.type === "swatch";

  // The listbox should be disabled when no options can be selected.
  // Note that we exclude the currently selected value from the list.
  const disabled =
    option.values.filter((o) => o.value !== selectedValue)?.length < 1;

  return (
    <Listbox
      className="min-w-[4em]"
      disabled={disabled}
      options={option.values}
      onChange={onChange}
      value={selectedValue}
      renderButton={({ currentValue, isExpanded, selectedOption }) => (
        <span
          data-expanded={isExpanded || undefined}
          className="flex items-center justify-center px-2 text-slate text-tiny expanded:bg-white"
        >
          {hasSwatch && selectedOption?.image && (
            <>
              <Swatch
                borderBehavior="always"
                image={selectedOption.image.src}
                size="xs"
                alt={selectedOption.image.alt}
              />
              &ensp;
            </>
          )}
          <span className="truncate">{currentValue}</span>
          &ensp;
          {!disabled && (
            <Icon
              name="chevron-down"
              className={classNames("text-tiny", {
                "rotate-180": isExpanded,
              })}
              size="inherit"
            />
          )}
        </span>
      )}
      renderOption={({ isActive, isDisabled, option: { image, value } }) =>
        // Don't render the currently selected value
        value === selectedValue ? null : (
          <span
            className={classNames("flex items-center px-2 text-tiny", {
              "text-charcoal": isActive,
              "text-slate": !isActive,
              "cursor-pointer": !isDisabled,
              "cursor-default": isDisabled,
            })}
          >
            {hasSwatch && image && (
              <>
                <Swatch
                  borderBehavior="always"
                  image={image.src}
                  size="xs"
                  alt={image.alt}
                />
                &ensp;
              </>
            )}
            <span className="truncate">{value}</span>
          </span>
        )
      }
      variant="tight"
      listPosition="top"
      listMobilePosition="top"
    />
  );
}

export interface RecommendedProductCardProps {
  addToCart?: boolean;
  product: RecommendedProduct;
  variant: "normal" | "tight" | "list";
  className?: string;
  onClick: (productVariant: RecommendedProduct["variants"][0]) => void;
  onViewed: (productVariant: RecommendedProduct["variants"][0]) => void;
  location?: "page" | "cart";
}

export function RecommendedProductCard({
  addToCart = false,
  product,
  variant,
  className,
  onClick,
  onViewed,
  location = "page",
}: RecommendedProductCardProps) {
  const router = useRouter();
  const formatPrice = usePriceFormatter();
  const { recordCustomItemInteraction } =
    React.useContext(CrossingMindsContext);
  const Image = React.useContext(ImageContext);
  const Link = React.useContext(LinkContext);

  const { data: cart } = useTypedQuery(["cart"]);
  const { logger } = useLogger();

  const { slug, title } = product;

  const { handleSelectedOptionChange, selectedVariant } =
    useSelectedVariant(slug);

  const containerRef = useRunOnVisibleOnce(
    () => onViewed(selectedVariant),
    {
      threshold: 0.8,
    },
    500
  );

  function getChangeHandler(name: string) {
    return (value: string) => {
      handleSelectedOptionChange({ name, value });
    };
  }

  const { mutate: addToCartMutation } = useTypedMutation(
    [CartMutation.AddToCart],
    {
      onError: (error) =>
        cartUtils.onCartError(
          error,
          logger,
          cart,
          "Error adding item to cart from recommended products"
        ),
    }
  );

  if (!selectedVariant) {
    return (
      <div
        className={classNames(
          "max-w-full flex flex-col items-center justify-center",
          {
            "px-4 py-3 bg-flint/10": variant === "tight",
          }
        )}
      >
        <LoadingIndicator />
      </div>
    );
  }

  const {
    id,
    images: [image],
    price,
    selectedOptions,
    available,
  } = selectedVariant;

  const handleAddToCart = () => {
    addToCartMutation({
      location:
        location === "cart" ? analytics.cartATCLocation : analytics.atcLocation,
      locale: router.locale as LocaleCode,
      lineItems: [
        {
          product,
          quantity: 1,
          variant: selectedVariant,
          customAttributes: toKeyValue({
            _list: "cart upsell",
            _attribution: "Rebuy Cart",
            _source: "Rebuy",
            _widget_id: "thuma_custom_cart_widget",
          }),
        },
      ],
    });
    if (location === "cart") {
      recordCustomItemInteraction({
        itemId: selectedVariant.id,
        interactionType: "rw_add_to_cart",
      });
    }
  };

  const options =
    variant === "normal"
      ? getDisplayedOptions(product.options)
      : product.options;

  const url = makeProductUrl(slug, id);
  const handleClick = () => onClick(selectedVariant);

  if (variant === "list") {
    const { primaryOptionValue, optionValues } = formatOptionValues(
      options,
      selectedOptions
    );

    return (
      <div data-testid={selectors.card} ref={containerRef}>
        <LineItem
          title={title}
          href={url}
          image={{
            src: image.url,
            height: image.height,
            width: image.width,
            alt: image.description || image.title,
          }}
          price={formatPrice(price)}
          primaryOptionValue={primaryOptionValue}
          optionValues={optionValues}
          onAdd={handleAddToCart}
          onClick={handleClick}
        />
      </div>
    );
  }

  return (
    <div
      data-testid={selectors.card}
      ref={containerRef}
      data-event-category="recommended-product-card"
      className={classNames(
        "max-w-full flex flex-col",
        {
          "px-4 py-3 bg-flint/10 justify-between": variant === "tight",
        },
        className
      )}
    >
      <div>
        {image && (
          <Link
            draggable={false}
            href={url}
            data-testid={
              variant === "tight" ? "cart-line-item-image" : undefined
            }
            onClick={handleClick}
          >
            <Image
              alt={image.description || image.title}
              data-testid="recommended-product-card-image"
              height={variant === "tight" ? 100 : 700}
              src={image.url}
              width={variant === "tight" ? 100 : 1050}
            />
          </Link>
        )}
        <div
          className={classNames("flex", {
            "justify-between mt-4 px-4": variant === "normal",
            "justify-center my-3 font-serif text-center leading-none h-8":
              variant === "tight",
          })}
        >
          <Link
            data-testid={
              variant === "tight" ? "cart-line-item-title" : undefined
            }
            draggable={false}
            href={url}
            onClick={handleClick}
          >
            {title}
          </Link>
          {variant === "normal" && (
            <span className="pl-2">{formatPrice(price)}</span>
          )}
        </div>
      </div>
      <div
        className={classNames("flex flex-col", {
          "items-center justify-start flex-1": variant === "tight",
        })}
      >
        {variant === "tight" &&
          options.map((option) => (
            <ProductOptions
              key={option.name}
              option={option}
              selectedValue={
                selectedOptions.find((o) => option.name === o.name)?.value
              }
              onChange={getChangeHandler(option.name)}
            />
          ))}
      </div>
      {addToCart && (
        <button
          disabled={!available}
          className="flex justify-center py-5 mt-3 -mx-4 -mb-3 text-xs leading-none text-white uppercase truncate bg-sage disabled:pointer-events-none disabled:opacity-50"
          data-testid="recommended-product-card-add-to-cart"
          onClick={handleAddToCart}
          type="button"
        >
          {/* TODO: localize this */}
          {available ? (
            <>
              <span className="mx-2 uppercase">Add</span>
              <span>|</span>
              <span className="mx-2">{formatPrice(price)}</span>
            </>
          ) : (
            <span>Out of Stock</span>
          )}
        </button>
      )}
    </div>
  );
}
