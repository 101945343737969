import { QueryClient } from "react-query";
import { OverlayProps } from "@components";
import { Entry } from "contentful";
import { SectionType } from "src/lib/components/RenderSections/types";
import { checkFeaturedImageAspectRatio } from "src/lib/services/catalog/utils";
import { addSpacerToSections } from "src/lib/util/addSpacerToSections";

import {
  IAd,
  ICollectionAssembly,
  ICollectionItem,
  ICollectionPageFields,
  ICollectionSection,
  ISectionGroup,
} from "../../../../@types/generated/contentful";
import { TextOverlayMediaProps } from "../../components";
import textMediaProps from "../../components/RenderSections/util";
import { buildCatalog } from "../../services/catalog/buildCatalog";
import { ProductCatalog } from "../../services/catalog/types";
import { ContentApiType } from "../../services/cms/api";
import ProductAPI from "../../services/product/api";
import {
  makeQueryFn,
  QueryClientPlugin,
} from "../../services/util/makeQueryClient";
import { getShopifyId } from "../../services/util/shopifyID";
import { isTruthy } from "../../util";
import makeProductUrl from "../../util/makeProductUrl";
import processSections from "../../util/process-sections";
import type { ServerDataServices } from "../server";

import type { WithProductID } from "./productData";

export type PartialCollectionItem = Partial<FormattedCollectionItem>;

export interface FormattedCollectionVariant extends WithProductID {
  id: string;
  sku: string | null;
  title: string;
  price: string;
  weight: number | null;
  available: boolean;
  selectedOptions: { name: string; value: string }[];
}

export interface FormattedCollectionItem {
  id: string | number;
  handle: string;
  key: string;
  productType: string;
  title: string;
  vendor?: string;
  image: {
    src: string;
    alt: string;
    width: number;
    height: number;
  };
  href: string;
  footerText: string;
  price: string;
  variants: Partial<FormattedCollectionVariant>[];
  optionName?: string | null;
  optionValues?: string[] | null;
  priceListing?:
    | "Show First"
    | "Show Minimum"
    | "Show Range"
    | "Hidden"
    | undefined;
  isFeatured?: boolean;
}

export interface FormattedCollection {
  title: string;
  items: FormattedCollectionItem[];
}

export interface FormattedAd extends OverlayProps {
  background: {
    alt?: string;
    url: string;
    title: string;
    description?: string;
  };
  cta?: {
    text?: string;
    url?: string;
  };
  key: string;
  title: string;
  textOverlayMedia?: TextOverlayMediaProps | null;
}

export function formatAds(ads: IAd[]): FormattedAd[] {
  return ads.map(
    ({
      fields: {
        background,
        cta,
        title,
        overlayColorTop,
        overlayColorMiddle,
        overlayColorBottom,
        overlayOpacity,
        textOverlayMedia,
      },
      sys,
    }) => ({
      background: {
        url: background?.fields.file.url ?? "https://via.placeholder.com/150",
        title: background?.fields.title ?? "placeholder_150x150",
        description: background?.fields.description || "",
        alt: background?.fields.description || background?.fields.title || "",
      },
      cta: {
        text: cta?.fields.text,
        url: cta?.fields.url,
      },
      key: sys.id,
      title,
      overlayColorTop: overlayColorTop || "black",
      overlayColorMiddle: overlayColorMiddle || "black",
      overlayColorBottom: overlayColorBottom || "black",
      overlayOpacity: overlayOpacity || "20",
      textOverlayMedia: textOverlayMedia
        ? textMediaProps(textOverlayMedia)
        : null,
    })
  );
}

export async function formatPage(
  page: Entry<ICollectionPageFields>,
  productAPI: ProductAPI,
  contentAPI: ContentApiType
) {
  const { fields } = page;
  return {
    topSections: await processSections(
      fields?.topSections || [],
      productAPI,
      contentAPI
    ).then(addSpacerToSections),
    sections: await processSections(
      fields?.sections || [],
      productAPI,
      contentAPI
    ).then(addSpacerToSections),
    bottomSections: await processSections(
      fields?.bottomSections || [],
      productAPI,
      contentAPI
    ).then(addSpacerToSections),
    pageMetadata: fields?.pageMetadata?.fields || null,
    layoutStyle: fields.layoutStyle,
    collectionItems: fields?.collectionItems ?? [],
    ads: formatAds(fields?.ads || []),
    collectionTitle: fields.collectionTitle,
    transparentNav: fields.transparentNav || false,
    bannerSet: fields.bannerSet || null,
  };
}

export function formatCmsItems(
  cmsItems: ICollectionItem[],
  catalog: ProductCatalog
): PartialCollectionItem[] {
  return cmsItems
    .map(
      ({
        sys: { id },
        fields,
        fields: {
          title,
          product: {
            fields: { product },
          },
          productVariants,
          optionName,
          optionValues,
          collectionItemImage,
          badgePreset,
        },
      }) => {
        const catalogProduct = product && catalog[getShopifyId(product)];
        if (!catalogProduct) return null;

        const { slug, productTitle } = catalogProduct;

        const href = makeProductUrl(
          slug,
          productVariants?.[0]?.fields?.variantId
        );

        const variants = productVariants?.map(({ fields: { variantId } }) => ({
          id: getShopifyId(variantId),
          productId: getShopifyId(product),
          price: catalogProduct.variants[getShopifyId(variantId)]?.price,
          title: catalogProduct.variants[getShopifyId(variantId)]?.title,
        }));

        const priceListing = catalogProduct.shouldHidePrice
          ? ("Hidden" as const)
          : fields.priceListing;

        return {
          id: getShopifyId(product),
          handle: slug,
          key: id,
          title: title ?? productTitle,
          href,
          variants,
          badgePreset: badgePreset || null,
          optionName: optionName || null,
          optionValues: optionValues || null,
          productType: catalogProduct.productType,
          image: {
            src: collectionItemImage?.fields?.file?.url ?? "",
            alt:
              collectionItemImage?.fields.description ||
              collectionItemImage?.fields.title ||
              productTitle,
            width:
              collectionItemImage?.fields?.file?.details?.image?.width ?? 1000,
            height:
              collectionItemImage?.fields?.file?.details?.image?.height ?? 1000,
          },
          footerText: productTitle,
          price: variants?.[0]?.price,
          ...(priceListing && {
            priceListing,
          }),
        };
      }
    )
    .filter(isTruthy);
}

export function validateCmsItems(
  cmsItems: ICollectionItem[],
  catalog: ProductCatalog
) {
  cmsItems.forEach((cmsItem) => {
    const productId = getShopifyId(cmsItem.fields.product.fields.product);
    const catalogProduct = catalog[productId];

    if (!catalogProduct) return;

    cmsItem.fields?.productVariants?.forEach((variantItem) => {
      const variantId = getShopifyId(variantItem.fields.variantId);

      if (!catalogProduct.variants[variantId]) {
        throw new Error(
          `Contentful data configuration error. Product variant in collection Item must belong to the parent product. Found issue with ${cmsItem.fields.name} collection Item entry.`
        );
      }
    });
  });
}

const isCollectionSection = (
  section: SectionType
): section is ICollectionSection =>
  section.sys.contentType.sys.id === "collectionSection";

const isSectionGroup = (section: SectionType): section is ISectionGroup =>
  section.sys.contentType.sys.id === "sectionGroup";
const isCollectionAssembly = (
  section: SectionType
): section is ICollectionAssembly =>
  section.sys.contentType.sys.id === "collectionAssembly";

export function validateFeaturedItems(
  sections: SectionType[],
  catalog: ProductCatalog
) {
  sections.forEach((section) => {
    if (isCollectionSection(section)) {
      const featuredItems = [
        ...(section.fields.featuredItems || []),
        ...(section.fields.featuredItemsDesktop || []),
      ];

      featuredItems.forEach((item) => {
        const productId = getShopifyId(item.fields.product.fields.product);
        const catalogProduct = catalog[productId];

        if (!catalogProduct)
          throw new Error(`Invalid product in featured items, ${productId}`);

        const currentVariantId = getShopifyId(
          item.fields.productVariants?.[0]?.fields?.variantId
        );

        const currentVariant = catalogProduct.variants[currentVariantId];
        if (!currentVariant)
          throw new Error(
            `Featured product in collection section must have a variant. Found issue with ${item.fields.title}`
          );

        // Find variant options if applicable
        const matchingOption = item.fields.optionName
          ? catalogProduct.options.find(
              (opt) => opt.name === item.fields.optionName
            )
          : null;

        const values = item.fields.optionValues
          ? item.fields.optionValues.map((value) =>
              matchingOption?.values?.find((v) => v.value === value)
            )
          : matchingOption?.values;

        const option = {
          ...matchingOption,
          values: values?.filter(isTruthy) || [],
        };

        const selectedOptions = Object.fromEntries(
          currentVariant.selectedOptions.map(({ name, value }) => [name, value])
        );

        const selectableOptions = {
          ...selectedOptions,
          ...(option && option.name
            ? { [option.name]: option.values.map(({ value }) => value) }
            : undefined),
        };

        const matchingVariants = Object.values(catalogProduct.variants).filter(
          (variant) =>
            Object.entries(selectableOptions).every(([optName, optVal]) =>
              variant.selectedOptions.some(
                (selectedOption) =>
                  selectedOption.name === optName &&
                  optVal.includes(selectedOption.value)
              )
            )
        );

        const missingFeaturedImageVariants = matchingVariants.filter(
          (variant) => !variant.cardImageFeatured?.url
        );

        if (missingFeaturedImageVariants.length) {
          const contentfulUrl =
            "https://app.contentful.com/spaces/t15gr55mpxw1/entries/";
          throw new Error(
            `Not all matching variants have a featured image for ${contentfulUrl}${
              item.sys.id
            }: ${missingFeaturedImageVariants
              .map((v) => `${contentfulUrl}${v.contentfulId}`)
              .join(", ")}`
          );
        }
        checkFeaturedImageAspectRatio(matchingVariants, item.sys.id);
      });
    } else if (isSectionGroup(section)) {
      validateFeaturedItems(section.fields.sections, catalog);
    } else if (isCollectionAssembly(section)) {
      validateFeaturedItems(section.fields.collectionSections, catalog);
    }
  });
}

export const collectionQueryFns = ({
  contentAPI,
  productAPI,
}: ServerDataServices) => ({
  collectionData: async (slug: string) => {
    const [page, catalog] = await Promise.all([
      contentAPI.getItemBySlug({
        contentType: "collectionPage",
        slug,
        include: 6,
      }),
      buildCatalog(productAPI, contentAPI),
    ]);

    if (!page) return null;

    const {
      topSections,
      sections,
      bottomSections,
      layoutStyle,
      pageMetadata,
      ads,
      collectionItems,
      collectionTitle,
      transparentNav,
      bannerSet,
    } = await formatPage(page, productAPI, contentAPI);

    validateCmsItems(collectionItems, catalog);
    validateFeaturedItems(topSections, catalog);
    validateFeaturedItems(sections, catalog);

    const items = formatCmsItems(collectionItems, catalog);

    return {
      title: collectionTitle ?? null,
      layoutStyle,
      items,
      sections,
      topSections,
      bottomSections,
      pageMetadata,
      ads,
      transparentNav,
      bannerSet,
    };
  },
  collectionPaths: async () => contentAPI.getPagePaths("collectionPage"),
});

const collectionPlugin: QueryClientPlugin<ServerDataServices> = (
  queryClient: QueryClient,
  dataServices
) => {
  const queries = collectionQueryFns(dataServices);
  const { collectionData, collectionPaths } = queries;

  queryClient.setQueryDefaults(["collectionData"], {
    queryFn: makeQueryFn(async (slug: string) => collectionData(slug)),
  });

  queryClient.setQueryDefaults(["collectionPaths"], {
    queryFn: makeQueryFn(collectionPaths),
  });
};

export default collectionPlugin;
