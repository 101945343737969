import classNames from "classnames";

export interface PromoBadgeProps {
  /** Should only be used for positioning */
  className?: string;

  /** The color of the badge background */
  bgColor?: "grey" | "white";

  /** The text to display in the badge */
  text: string;
}

/**
 * Component used to render a promotional badge
 */
export default function PromoBadge({
  className,
  bgColor = "white",
  text,
}: PromoBadgeProps) {
  return (
    <span
      data-testid="promo-badge"
      className={classNames(
        "text-graphite text-style-badge py-0.5 px-1 gap-2.5 whitespace-nowrap",
        {
          "bg-brand-grey": bgColor === "grey",
          "bg-white": bgColor === "white",
        },
        className
      )}
    >
      {/* truncate text at 16 characters */}
      {text.trim().substring(0, 16)}
    </span>
  );
}
