import * as React from "react";

import { ImageProps } from "../../../components/Image/Image";
import makeSrcWithProtocol from "../../util/makeSrcWithProtocol";

export function NativeImage({
  src,
  alt,
  height,
  width,
  className,
  objectFit = "cover",
  style,
  // Not used, but required for compatibility with ImageProps
  heightOverride,
  // Not used, but required for compatibility with ImageProps
  priority,
  // Not used, but required for compatibility with ImageProps
  quality,
  ...props
}: ImageProps & React.ImgHTMLAttributes<HTMLImageElement>) {
  return (
    // eslint-disable-next-line @next/next/no-img-element
    <img
      src={makeSrcWithProtocol(src)}
      alt={alt}
      height={height}
      width={width}
      style={{ objectFit, ...(style ?? {}) }}
      draggable={false}
      className={className}
      {...props}
    />
  );
}
