const selectors = {
  card: "recommended-product-card",
  placeholder: "recommended-products-placeholder",
  price: "recommended-product-price",
  title: "recommended-product-title",
  image: "recommended-product-card-image",
};

export const analytics = {
  cartATCLocation: "cart-recommendations",
  atcLocation: "recommendations",
};

export default selectors;
