const SHOPIFY_PREFIX = "gid://shopify/";

/** encodes a string into a base64 buffer */
const universalBtoA = (str: string) => {
  try {
    return Buffer.from(str).toString("base64");
  } catch (err) {
    return btoa(str);
  }
};

/** decodes a base64 buffer */
const universalAtoB = (b64Encoded: string) => {
  try {
    return Buffer.from(b64Encoded, "base64").toString();
  } catch (err) {
    return atob(b64Encoded);
  }
};

export const isValidShopifyId = (id: string | number) =>
  typeof id === "number" || (typeof id === "string" && !Number.isNaN(+id));

export const getShopifyId = (encoded: string | number | undefined = "") => {
  // If it is already a shopify id then return it. Otherwise, decode it.
  if (isValidShopifyId(encoded)) return encoded.toString();

  const encodedString = encoded.toString();
  const decodedString = encodedString.startsWith(SHOPIFY_PREFIX)
    ? encodedString
    : universalAtoB(encodedString);
  const gid = decodedString.replace(SHOPIFY_PREFIX, "");
  const parts = gid.split("/");
  const [shopifyId] = parts.slice(parts.length - 1);

  return shopifyId;
};

export const encodeShopifyProductId = (productId: string | number) => {
  const raw = `${SHOPIFY_PREFIX}Product/${productId}`;
  const encoded = universalBtoA(raw);
  return encoded;
};

export const encodeShopifyVariantId = (variantId: string | number) => {
  const raw = `${SHOPIFY_PREFIX}ProductVariant/${variantId}`;
  const encoded = universalBtoA(raw);

  return encoded;
};
