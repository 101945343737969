import React from "react";
import { BLOCKS, Document, Node } from "@contentful/rich-text-types";
import classNames from "classnames";

import { CTA, RichText, SpacerSection } from "../../lib/components";
import {
  getIdAndFields,
  isCtaButton,
  isSpacerSection,
} from "../../lib/components/RichText";
import { AnalyticsProvider } from "../../lib/hooks/useAnalytics";
import { Heading } from "../Heading";
import { ImageContext } from "../Image";
import { ImageProps } from "../Image/Image";
import { LinkContext } from "../Link";
import PromoBadge from "../PromoBadge";

import selectors from "./selectors";

interface Props {
  /** Required properties to render the card image */
  image: ImageProps;

  /** An href value for the Link component */
  href?: string;

  /** Optional title to display below the image */
  title?: string;

  /** Optional body richtext to display below title */
  body?: Document;

  /* An optional text for the title badge */
  badgeText?: string;

  /* A prop whether to underline the title */
  underline?: boolean;

  /* A click handler to track the click event */
  clickHandler?: React.MouseEventHandler<HTMLElement>;
}

function CardV2Wrapper({
  href,
  title,
  clickHandler,
  children,
}: React.PropsWithChildren<Pick<Props, "href" | "title" | "clickHandler">>) {
  const LinkElement = React.useContext(LinkContext);

  if (!href)
    return (
      <button
        type="button"
        className="text-left cursor-default"
        onClick={clickHandler}
      >
        {children}
      </button>
    );

  return (
    <LinkElement
      href={href}
      draggable={false}
      title={title}
      data-testid={selectors.cardLink}
      onClick={clickHandler}
    >
      {children}
    </LinkElement>
  );
}

const renderEmbedded = (node: Node) => {
  if (isSpacerSection(node)) {
    const { size } = getIdAndFields(node).fields;
    return (
      <SpacerSection
        size={size}
        renderAs="inline"
        dataTestId={selectors.spacer}
      />
    );
  }
  if (isCtaButton(node)) {
    const {
      fields: { text, name, url, variant: rawVariant, size, arrowIcon },
      contentfulId: id,
    } = getIdAndFields(node);

    const variant =
      rawVariant === "underline" ? "button-underline" : rawVariant;
    return (
      <div className="my-1" data-testid={selectors.cardCTA}>
        <AnalyticsProvider object={{ name, id }}>
          <CTA
            url={url}
            variant={variant}
            size={size}
            arrowIcon={arrowIcon}
            className="hover:!text-charcoal"
          >
            {text}
          </CTA>
        </AnalyticsProvider>
      </div>
    );
  }
  return null;
};

const underlineStyles =
  "underline underline-offset-4 full:underline-offset-8 decoration-1";

export const CardV2 = React.forwardRef<HTMLDivElement, Props>(
  ({ image, href, title, body, underline, badgeText, clickHandler }, ref) => {
    const ImageElement = React.useContext(ImageContext);

    return (
      <CardV2Wrapper href={href} clickHandler={clickHandler}>
        <div className="relative flex flex-col" ref={ref}>
          <ImageElement
            src={image.src}
            alt={image.alt}
            height={image.height}
            width={image.width}
            data-testid={selectors.image}
          />
          {title ? (
            <div className="flex items-start mt-3 full:mt-4">
              <Heading
                as="h5"
                data-testid={selectors.title}
                className={classNames(
                  "inline-block font-serif text-l leading-[22.5px] full:text-[25px] full:leading-7 [text-wrap:wrap]",
                  underline && underlineStyles
                )}
              >
                {title}
              </Heading>
              {badgeText ? (
                <PromoBadge text={badgeText} className="ml-3" />
              ) : null}
            </div>
          ) : null}
          {body ? (
            <div className="mt-0.5 whitespace-normal">
              <RichText
                richTextResponse={body}
                customOptions={{
                  [BLOCKS.EMBEDDED_ENTRY]: renderEmbedded,
                }}
              />
            </div>
          ) : null}
        </div>
      </CardV2Wrapper>
    );
  }
);

CardV2.displayName = "CardV2";
