/* eslint-disable import/prefer-default-export */
import * as React from "react";
import { LinkContext } from "@components";
import classNames from "classnames";

import { ContextLinkProps } from "../../../components/Link/Link";
import { useAnalytics } from "../../hooks/useAnalytics";
import { addHeapOnRichtextCTAClick } from "../../services/elevar/events";

const sizeClasses = { sm: "w-[120px]", md: "w-[173px]", lg: "w-80" };

export interface CTAProps {
  url?: string;
  variant: ContextLinkProps["variant"];
  size?: keyof typeof sizeClasses;
  arrowIcon?: ContextLinkProps["arrowIcon"];
  children: React.ReactNode;
  className?: string;
  textClassName?: string;
  "data-testid"?: string;
  onClick?: () => void;
}

/**
A component that renders a Link based on data received from CMS
 */
export function CTA({
  children,
  url,
  variant,
  size,
  arrowIcon,
  className,
  textClassName,
  "data-testid": testId = "cta-link-element",
  onClick,
}: CTAProps) {
  const { useContext } = React;
  const LinkElement = useContext(LinkContext);
  const { currentObject, parentObject } = useAnalytics();
  const handleClick = () => {
    addHeapOnRichtextCTAClick({ currentObject, parentObject });
    onClick?.();
  };

  return (
    <LinkElement
      href={url}
      variant={variant}
      arrowIcon={arrowIcon}
      data-testid={testId}
      data-event-category="cta click"
      onClick={handleClick}
      className={classNames(
        "relative pointer-events-auto",
        { "px-12": variant?.includes("button") && !size },
        size && [sizeClasses[size], "text-center max-w-full"],
        className
      )}
    >
      <span
        className={classNames("text-xs", textClassName)}
        data-testid={`${testId}-content`}
      >
        {children}
      </span>
    </LinkElement>
  );
}
