/* eslint-disable react/jsx-props-no-spreading */
/* eslint-disable import/prefer-default-export */
import * as React from "react";
import { Link as LinkComponent } from "@components";
import Link from "next/link";
import { useRouter } from "next/router";
import useLogger from "src/hooks/useLogger";
import { ContentModuleContext } from "src/lib/context/ContentModuleContext";
import { classicRenaming } from "src/lib/data/redirectsMap";
import { AnalyticsContext } from "src/lib/hooks/useAnalytics";
import {
  addHeapContentModuleClicked,
  getLocationFromName,
} from "src/lib/services/elevar/events";

import { ContextLinkProps } from "../../../components/Link/Link";
import { getTranslatedPath } from "../../routes/routeConfig";
import { getSectionPosition } from "../RenderSections/util";

import useSmoothScrolling from "./useSmoothScrolling";

export function getRedirectedHref(href: string | undefined) {
  const [path, query] = href ? href.split("?") : [undefined, undefined];
  const redirectedHref =
    path && path in classicRenaming
      ? [classicRenaming[path], query].filter(Boolean).join("?")
      : href;
  return redirectedHref;
}

export function isExternalLink(href: string | undefined) {
  if (!href) return false;

  try {
    const url = new URL(href, window.location.origin);
    return (
      url.hostname !== window.location.hostname &&
      !href.includes("checkout.thuma.co") // Treat checkout links as internal
    );
  } catch (e) {
    // If it's not a valid URL, assume it's internal
    return false;
  }
}

/**
 A site link component that wraps next/link around the component library Link component
 */
export const SiteLink = React.forwardRef<
  HTMLAnchorElement,
  React.PropsWithChildren<ContextLinkProps>
>(
  (
    {
      href,
      variant,
      children,
      className,
      draggable,
      disabled,
      arrowIcon,
      onClick,
      ...props
    },
    ref
  ) => {
    const router = useRouter();
    const [target, setTarget] = React.useState<"_blank" | undefined>(undefined);
    const { logger } = useLogger();
    // TODO: remove this at some point
    // Temporary local redirects for classic products
    const redirectedHref = getRedirectedHref(href);
    const translatedHref = redirectedHref
      ? getTranslatedPath(redirectedHref, {
          ...router,
          locale: props.locale ?? router?.locale,
        })
      : undefined;

    // setting prefetch to `true` is deprecated
    // https://nextjs.org/docs/messages/prefetch-true-deprecated
    const prefetch = router?.isPreview ? false : undefined;

    const handleScrollOrNavigate = useSmoothScrolling(translatedHref);

    const moduleContext = React.useContext(ContentModuleContext);
    const analyticsContext = React.useContext(AnalyticsContext);

    // TODO: figure out how to cover this
    // istanbul ignore next
    const handleLinkClick = (event: React.MouseEvent<HTMLAnchorElement>) => {
      if (redirectedHref !== href) {
        logger.info("Classic Renaming: Redirecting Href", {
          old: href,
          new: redirectedHref,
          url: router.asPath,
        });
      }
      handleScrollOrNavigate(event);
      // If the link takes you to a different page and is a module element, track the click
      if (moduleContext && !translatedHref?.includes("#")) {
        addHeapContentModuleClicked({
          ...moduleContext,
          modulePosition: getSectionPosition(moduleContext.positionId),
          moduleName: getLocationFromName(
            undefined,
            moduleContext.moduleEntryName
          ),
          elementName: analyticsContext?.tree.at(-1)?.name,
          elementType: analyticsContext?.tree.at(-1)?.type,
        });
      }
      onClick?.(event);
    };

    const isWindowDefined = typeof window !== "undefined";

    // This state pattern is needed because we cannot determine if a link is external on the server.
    // This ensures we are client side and have the window object available to determine if the link is external.
    React.useEffect(() => {
      if (isExternalLink(href)) {
        setTarget("_blank");
      }
    }, [href, isWindowDefined]);

    const innerContent = (
      <LinkComponent
        target={target}
        {...props}
        href={translatedHref}
        onClick={handleLinkClick}
        variant={variant}
        className={className}
        disabled={disabled}
        draggable={draggable}
        arrowIcon={arrowIcon}
        ref={ref}
      >
        {children}
      </LinkComponent>
    );

    // If it is an external link, use a regular anchor tag
    if (isExternalLink(href)) return innerContent;
    // If it is an internal link that is not a hash link, use next/link
    if (translatedHref && !translatedHref.includes("#"))
      return (
        <Link href={translatedHref} passHref prefetch={prefetch}>
          {innerContent}
        </Link>
      );

    return innerContent;
  }
);

SiteLink.displayName = "SiteLink";
