import { useContext } from "react";
import {
  documentToReactComponents,
  Options,
} from "@contentful/rich-text-react-renderer";
import { BLOCKS, Document, INLINES, MARKS } from "@contentful/rich-text-types";
import { LinkContext } from "src/components/Link";

import { Heading } from "../Heading";
import { Text } from "../Text";

import selectors from "./selectors";

interface RichTextProps {
  /** Rich text object received from Contentful API */
  richTextResponse: Document;

  /** Custom render options for the placement */
  customOptions?: Options["renderNode"];
}

const baseOptions: Options = {
  renderNode: {
    [BLOCKS.HEADING_1]: (_node, children) => (
      <Heading as="h1" data-testid={selectors.h1}>
        {children}
      </Heading>
    ),
    [BLOCKS.HEADING_2]: (_node, children) => (
      <Heading as="h2" data-testid={selectors.h2}>
        {children}
      </Heading>
    ),
    [BLOCKS.HEADING_3]: (_node, children) => (
      <Heading as="h3" data-testid={selectors.h3}>
        {children}
      </Heading>
    ),
    [BLOCKS.HEADING_4]: (_node, children) => (
      <Heading as="h4" data-testid={selectors.h4}>
        {children}
      </Heading>
    ),
    [BLOCKS.HEADING_5]: (_node, children) => (
      <Heading as="h5" data-testid={selectors.h5}>
        {children}
      </Heading>
    ),
    [BLOCKS.HEADING_6]: (_node, children) => (
      <Heading as="h6" data-testid={selectors.h6}>
        {children}
      </Heading>
    ),
    [BLOCKS.PARAGRAPH]: (_node, children) => (
      <Text data-testid={selectors.p}>{children}</Text>
    ),
    [INLINES.HYPERLINK]: (node, children) => {
      const {
        data: { uri },
        content,
      } = node;

      const LinkElement = useContext(LinkContext);

      const contentMarks = content
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        .map((i: any) => i.marks)
        .flat()
        .map((i) => i.type);

      const hasUnderline = contentMarks.includes("underline");

      return (
        <LinkElement
          href={uri}
          variant={hasUnderline ? "link" : "underline"}
          data-testid={selectors.hyperlink}
          className={hasUnderline ? "hover:opacity-[0.85]" : undefined}
        >
          {children}
        </LinkElement>
      );
    },
  },
  renderMark: {
    [MARKS.BOLD]: (text) => <span className="font-bold">{text}</span>,
  },
  renderText: (text) => (text === "" ? <br /> : text),
};

export function RichText({
  richTextResponse,
  customOptions = {},
}: RichTextProps) {
  const options: Options = {
    ...baseOptions,
    renderNode: {
      ...baseOptions.renderNode,
      ...customOptions,
    },
  };

  return <>{documentToReactComponents(richTextResponse, options)}</>;
}
