/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable react/jsx-no-bind */
/* eslint-disable import/prefer-default-export */
import * as React from "react";
import { Icon, LinkContext } from "@components";
import classNames from "classnames";

import { ImageContext } from "../Image";
import { ImageProps } from "../Image/Image";

import CardContent from "./CardContent";
import selectors from "./selectors";
import { useFeaturedCardHeight } from "./useFeaturedCardHeight";

function CardWrapper({ href, children }: Pick<Props, "href" | "children">) {
  const LinkElement = React.useContext(LinkContext);

  // eslint-disable-next-line react/jsx-no-useless-fragment
  if (!href) return <>{children}</>;

  return (
    <LinkElement href={href} draggable={false}>
      {children}
    </LinkElement>
  );
}

interface Props {
  /** Required properties to render the card image */
  image: ImageProps;

  /** Optional desktop image that renders on hover */
  hoverImage?: ImageProps;

  /** A boolean to determine if the card is featured */
  isFeatured?: boolean;

  /** An href value for the Link component */
  href?: string;

  /** Optional title to display below the image */
  title?: string;

  /** Optional body text to display below the image */
  bodyText?: string;

  /** A string for footer text */
  footerText?: string;

  /** A string for formatted price  */
  price?: string;

  /** A prop to enable icon  */
  icon?: boolean;

  /** Optional callback called on click of the card */
  onClick?: React.EventHandler<React.MouseEvent<HTMLDivElement>>;

  /* A prop to show banner label */
  bannerLabel?: string;

  /* A prop whether to underline footer on hover */
  underline?: boolean;

  /** Children to show above the card image for composability */
  children?: React.ReactNode;

  forwardedRef?: React.Ref<HTMLDivElement>;
}

type PriceIconProps =
  | { icon?: boolean; price?: never }
  | { price?: string; icon?: never };

export type CardProps = Props & PriceIconProps;

const withIconClasses = "flex-1";
const withPriceClasses = "flex-1 text-left";
const plainCardClasses = "flex-1";

/** A clickable card component showcasing a product image, footer text and price or icon */

export function Card({
  image: { src, alt, width, height },
  hoverImage,
  isFeatured = false,
  title,
  bodyText,
  footerText,
  price,
  icon,
  href,
  onClick,
  bannerLabel,
  underline,
  children,
  forwardedRef,
}: CardProps) {
  const ImageElement = React.useContext(ImageContext);

  const { featuredCardHeight, setCardRef } = useFeaturedCardHeight();

  function getFooterClasses() {
    return classNames({
      [withIconClasses]: icon,
      [withPriceClasses]: price,
      [plainCardClasses]: !icon && !price,
      "group-hover:underline": underline,
    });
  }

  const cardHeight =
    isFeatured && featuredCardHeight ? featuredCardHeight : height;

  return (
    <div className="relative" onClick={onClick} ref={forwardedRef}>
      <CardWrapper href={href}>
        <div
          className="relative flex"
          data-testid={selectors.cardImageContainer}
          ref={!isFeatured ? setCardRef : undefined}
        >
          <div className="absolute z-[-1] w-full h-full bg-lightgray animate-pulse" />
          <ImageElement
            src={src}
            alt={alt}
            width={width}
            height={cardHeight}
            style={{
              maxHeight:
                isFeatured && featuredCardHeight
                  ? featuredCardHeight
                  : undefined,
            }}
            heightOverride={isFeatured && !!featuredCardHeight}
            quality={90}
          />
          {hoverImage ? (
            <div
              className="absolute inset-0 hidden opacity-0 full:flex hover:opacity-100"
              data-testid={selectors.hoverImageWrapper}
            >
              <ImageElement
                src={hoverImage.src}
                alt={hoverImage.alt}
                width={width}
                height={cardHeight}
                // This assumes that the hover image is the same size as the featured image
                style={{
                  maxHeight:
                    isFeatured && featuredCardHeight
                      ? featuredCardHeight
                      : undefined,
                }}
                heightOverride={isFeatured && !!featuredCardHeight}
                quality={90}
              />
            </div>
          ) : null}
          {children}
        </div>

        <CardContent title={title} bodyText={bodyText} />
        <div
          className={classNames(
            "flex gap-1 justify-between w-full mt-1 text-2xs full:text-base leading-[180%] full:mt-3 full:flex-row",
            {
              "flex-col": !icon,
            }
          )}
        >
          <div
            className={getFooterClasses()}
            data-testid={selectors.cardFooter}
          >
            {footerText}
          </div>
          {price && !icon && (
            <div className="truncate full:text-right">{price}</div>
          )}
          {icon && !price && (
            <div>
              <Icon name="arrow-right" />
            </div>
          )}
        </div>
      </CardWrapper>
      {bannerLabel ? (
        <div className="absolute top-0 left-0 px-6 text-white uppercase full:py-2 full:my-5 text-tiny bg-slate">
          {bannerLabel}
        </div>
      ) : null}
    </div>
  );
}
