import { createContext, ReactNode, useContext, useMemo } from "react";

export const CartShippingMessageContext = createContext<
  string | null | undefined
>(null);

export function useCartShippingMessage() {
  return useContext(CartShippingMessageContext);
}

export function CartShippingMessageContextProvider({
  children,
  cartShippingMessage,
}: {
  children: ReactNode;
  cartShippingMessage?: string | null;
}) {
  const cartShippingMessageMemoized = useMemo(
    () => cartShippingMessage,
    [cartShippingMessage]
  );
  return (
    <CartShippingMessageContext.Provider value={cartShippingMessageMemoized}>
      {children}
    </CartShippingMessageContext.Provider>
  );
}
