/* eslint-disable import/prefer-default-export */
import * as React from "react";
import { Card, Carousel, Heading } from "@components";
import classNames from "classnames";
import { useRouter } from "next/router";
import { addHeapSearchSubmitted } from "src/lib/services/elevar/events";

import useCatalog from "../../hooks/useCatalog";
import useSearch from "../../hooks/useSearch";
import { useTypedQuery } from "../../hooks/useTypedQuery";
import { ProductCatalog } from "../../services/catalog/types";
import { ISearchProduct } from "../../services/search/types";
import {
  getCatalogProductAndVariantFromSearchSpringItem,
  getProductIdFromSearchSpringUID,
} from "../../util/searchUtils";
import LinkGroup from "../Navigation/components/LinkGroup";
import { DesktopNavigationProps, LinkItemProps } from "../Navigation/types";
import { isExpandedSearchSpringProduct } from "../SearchResultsSection/utils";

import SearchBar from "./components/SearchBar";
import selectors from "./selectors";

interface SearchPaneProps {
  featuredProducts: DesktopNavigationProps["featuredProducts"];
  mobile?: boolean;
  onClose: () => void;
}

const getUniqueResults = (links: LinkItemProps[]) => {
  const uniqueLinks = links.reduce<Record<string, LinkItemProps>>(
    (foundItems, currentItem) => {
      if (foundItems[currentItem.text]) return foundItems;
      return { ...foundItems, [currentItem.text]: currentItem };
    },
    {}
  );
  return Object.values(uniqueLinks);
};

const PRODUCTS_PER_VIEW = 5;
const IMAGE_SIZE = 600;
const getResultLinks = (results: ISearchProduct[], catalog: ProductCatalog) =>
  results
    .filter(isExpandedSearchSpringProduct)
    .reduce<{ items: LinkItemProps[]; imageSet: Set<string> }>(
      (acc, product) => {
        const { product: catalogProduct, variant } =
          getCatalogProductAndVariantFromSearchSpringItem(product, catalog);
        if (!catalogProduct || !variant) return acc;

        const { url: src } = variant.images[0];

        if (acc.imageSet.has(src)) return acc;
        acc.imageSet.add(src);

        return {
          ...acc,
          items: [
            ...acc.items,
            {
              id: catalogProduct.id,
              name: catalogProduct.productTitle,
              text: catalogProduct.productTitle,
              url: `/products/${catalogProduct.slug}?variant=${variant.id}`,
              image: { src, height: IMAGE_SIZE, width: IMAGE_SIZE },
            },
          ],
        };
      },
      { items: [], imageSet: new Set<string>() }
    ).items;
/**
 * Renders a search bar and results
 */
export function SearchPane({
  featuredProducts,
  mobile,
  onClose,
}: SearchPaneProps) {
  const router = useRouter();
  React.useEffect(() => {
    router.events?.on("routeChangeStart", onClose);

    return () => {
      router.events?.off("routeChangeStart", onClose);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const { query, setQuery, onSubmit, isSubmitting } = useSearch();
  const { data, isLoading, isError } = useTypedQuery([
    "autocompleteWithSuggestions",
    query,
  ]);

  const productIds: string[] =
    data?.autocomplete?.results?.map((result) =>
      getProductIdFromSearchSpringUID(result.uid)
    ) ?? [];

  const { catalog } = useCatalog(productIds);
  const autocomplete = data?.autocomplete;

  const noAutocompleteResults =
    !query || !autocomplete || typeof autocomplete === "string";

  const products: LinkItemProps[] =
    catalog && !noAutocompleteResults
      ? getResultLinks(autocomplete.results, catalog)
      : featuredProducts.map((product) => ({
          id: product.id,
          name: product.title,
          text: product.title,
          url: `/products/${product.slug}`,
          image: { ...product.image, height: IMAGE_SIZE, width: IMAGE_SIZE },
        }));

  const shouldShowNavigation = products.length >= PRODUCTS_PER_VIEW;
  const handleOnSubmit = (value: string) => {
    onSubmit(value);
    addHeapSearchSubmitted({
      location: "search pane",
      searchType: "manually typed",
      searchTerm: value,
    });
  };

  return (
    <div
      className="flex flex-col gap-8"
      data-testid={selectors.SearchPane.container}
    >
      <SearchBar
        query={query}
        setQuery={setQuery}
        onSubmit={handleOnSubmit}
        isLoading={isSubmitting}
        mobile={mobile}
        autoFocus
      />
      {/* Featured products & results */}
      <div
        className="grid-cols-6 gap-8 lg:grid"
        data-testid={selectors.SearchPane.resultsContainer}
        data-loading={isLoading}
        data-error={isError}
      >
        <div
          className={classNames("flex flex-col gap-4 pr-4", {
            "border-r border-lightgray": !mobile,
          })}
        >
          <LinkGroup
            title={!query ? "Most Searched" : "Results"}
            id="link-group-search-results"
            name="link-group-search-results"
            highlightTitle
            useBoldHeadings={!mobile}
            useUppercaseTitle={!mobile}
            links={isLoading ? undefined : getUniqueResults(products)}
            trackClick={(item: LinkItemProps) => {
              addHeapSearchSubmitted({
                location: "search pane",
                searchType: "most searched",
                searchTerm: item.text,
              });
            }}
          >
            {isError
              ? "Something went wrong. Please try again."
              : "No items matched your search term."}
          </LinkGroup>
        </div>
        {!mobile && (
          <div className="col-span-5">
            <Heading
              as="h6"
              fontFamily="sans"
              className="mb-5 uppercase !text-tiny font-bold"
            >
              {query ? "Top Results" : "Recent Searches"}
            </Heading>

            {/* featured products/result carousel */}
            {products.length > 0 && (
              <Carousel
                alignSlides="center"
                navigation={
                  shouldShowNavigation && {
                    position: "outside",
                    alignment: "top-half",
                  }
                }
                autoplay={{ enabled: false }}
                loop={shouldShowNavigation}
                touchStartPreventDefault={false}
                pagination={{
                  clickable: true,
                  type: "bullets",
                  position: "center",
                }}
                slidesPerView={PRODUCTS_PER_VIEW}
                spaceBetween={16}
                className={classNames({
                  "-mx-6": shouldShowNavigation,
                  "-mx-1": !shouldShowNavigation,
                })}
              >
                {products.map((item) => (
                  <div key={item.text} className="mx-1.5">
                    <Card
                      href={item.url}
                      // @ts-expect-error the product has an image
                      image={item.image}
                      footerText={item.text}
                      alt={item.alt}
                      onClick={() =>
                        addHeapSearchSubmitted({
                          location: "search pane",
                          searchType: query ? "top results" : "recent searches",
                          searchTerm: item.text,
                        })
                      }
                    />
                  </div>
                ))}
              </Carousel>
            )}

            {/* empty/error state */}
            {query && !products.length && (
              <div className="grid w-full h-24 place-items-center">
                {!isError ? (
                  <p data-testid={selectors.SearchPane.emptyStateMessage}>
                    No matches for <strong>{query}</strong>
                  </p>
                ) : (
                  <p
                    data-testid={selectors.SearchPane.errorStateMessage}
                    className="text-blush"
                  >
                    Something went wrong. Please try again later
                  </p>
                )}
              </div>
            )}
          </div>
        )}
      </div>
    </div>
  );
}
