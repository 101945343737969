/* eslint-disable react/no-array-index-key */
import * as React from "react";
import { AccordionV2, Button, Icon } from "@components";
import classNames from "classnames";
import { addHeapNavigationItemClick } from "src/lib/services/elevar/events";

import selectors from "../selectors";
import type { CardGroupItem, CardItemProps, MobileMenuItem } from "../types";

import { BackButton } from "./BackButton";
import LinkItem from "./LinkItem";
import { MobileCardGroup } from "./MobileCardGroup";
import SlidingTransition from "./SlidingTransition";

export interface MenuStackV2Props {
  title?: string | null;
  onBack?: () => void;
  items: {
    topItems?: CardGroupItem | null;
    mainItems: Array<MobileMenuItem | CardGroupItem>;
  };
  addPadding?: boolean;
  showChevronOverride?: boolean;
  children?: React.ReactNode;
  isOpen?: boolean;
  level?: number;
  resetScroll: () => void;
}
export default function MenuStackV2({
  title,
  items: { topItems, mainItems },
  onBack,
  addPadding,
  children,
  isOpen,
  level = 1,
  resetScroll,
}: MenuStackV2Props) {
  const [activeIndex, setActiveIndex] = React.useState(-1);

  // Reset the menu stack whenever the open state changes
  React.useEffect(() => {
    setActiveIndex(-1);
    resetScroll();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isOpen]);

  const topListItems = topItems?.items || [];

  const handleClickAnalytics = (
    item: MobileMenuItem | CardItemProps,
    linkLevel: number
  ) => {
    addHeapNavigationItemClick({
      style: item.type,
      isMobile: true,
      hasImage: Boolean((item as CardItemProps).image?.src),
      level: linkLevel,
      version: "modern",
      title: item.title || (item as MobileMenuItem).text,
      href: item.url,
      badgeText: (item as MobileMenuItem)?.badge,
    });
  };

  return (
    <div className={classNames("relative", { "px-8 pb-8": addPadding })}>
      <SlidingTransition
        show={activeIndex === -1}
        unmount={false}
        className="flex flex-col duration-300"
        exitLeft
      >
        {/* title (back) button */}
        {title && <BackButton text={title} onClick={onBack} />}

        {/* top item list */}
        {topItems && Boolean(topListItems.length) && (
          <MobileCardGroup
            {...topItems}
            onClick={(cardItem: CardItemProps) =>
              handleClickAnalytics(cardItem, level)
            }
            className="pb-6"
          />
        )}

        {/* main item list */}
        {Boolean(mainItems.length) && (
          <ul className="flex-1">
            {mainItems.map((item, itemIndex) => {
              if (item.itemType === "mobileMenuItem") {
                const analyticsFunction = () =>
                  handleClickAnalytics(item, level);
                switch (item.type) {
                  case "sheet":
                    return (
                      <li key={item.id} className="border-b border-b-lightgray">
                        <Button
                          variant="unstyled"
                          className="flex items-center justify-between w-full gap-4 py-5 leading-tight text-left"
                          onClick={() => {
                            setActiveIndex(itemIndex);
                            handleClickAnalytics(item, level);
                          }}
                        >
                          {item.title}
                          <Icon
                            name="chevron-right"
                            className="text-xs"
                            size="inherit"
                          />
                        </Button>
                      </li>
                    );
                  case "accordion":
                    return (
                      <li key={item.id} className="border-b border-b-lightgray">
                        <AccordionV2
                          name={item.name}
                          collapsible
                          iconClassName="flex items-center justify-center w-6 h-6"
                          onClick={analyticsFunction}
                          items={[
                            {
                              title: item.title || "",
                              id: `${item.id}-accordion`,
                              children: item.children?.map((accordionItem) => {
                                if (
                                  accordionItem.itemType === "mobileMenuItem"
                                ) {
                                  if (
                                    accordionItem.type === "link" &&
                                    accordionItem.text &&
                                    accordionItem.url
                                  ) {
                                    return (
                                      <div
                                        className="mb-2 space-y-2 px-9"
                                        key={accordionItem.id}
                                      >
                                        <LinkItem
                                          onClick={() =>
                                            handleClickAnalytics(
                                              accordionItem,
                                              level + 1
                                            )
                                          }
                                          url={accordionItem.url}
                                          text={accordionItem.text}
                                          highlight={accordionItem.highlight}
                                          badge={accordionItem.badge}
                                          mobile
                                          className="py-1 leading-normal"
                                        />
                                      </div>
                                    );
                                  }
                                  throw new Error(
                                    "Invalid accordion child type"
                                  );
                                } else {
                                  return (
                                    <MobileCardGroup
                                      {...accordionItem}
                                      key={accordionItem.id}
                                      onClick={(cardItem) =>
                                        handleClickAnalytics(cardItem, level)
                                      }
                                    />
                                  );
                                }
                              }),
                            },
                          ]}
                        />
                      </li>
                    );
                  case "link":
                    if (item.text && item.url) {
                      return (
                        <li
                          key={item.id}
                          className={classNames(
                            !item.highlight && "border-b border-b-lightgray"
                          )}
                        >
                          <LinkItem
                            url={item.url}
                            text={item.text}
                            highlight={item.highlight}
                            onClick={analyticsFunction}
                            badge={item.badge}
                            mobile
                          />
                        </li>
                      );
                    }
                    return null;
                  case "cards": // Cards case has already been transformed into CardGroupItem
                  default:
                    return null;
                }
              }
              // Else, it's a CardGroupItem
              else {
                return (
                  <li key={item.id}>
                    <MobileCardGroup
                      {...item}
                      onClick={(cardItem) =>
                        handleClickAnalytics(cardItem, level)
                      }
                      // This classname is to add top margin when the card group is not the first item
                      className={classNames(itemIndex !== 0 && "mt-8")}
                    />
                  </li>
                );
              }
            })}
          </ul>
        )}
        {children}
      </SlidingTransition>

      {/* child menus (absolute positioned) */}
      {mainItems
        .filter(
          (item): item is MobileMenuItem =>
            item.itemType === "mobileMenuItem" && item.type === "sheet"
        )
        .map((item, itemIndex) =>
          item.children ? (
            <SlidingTransition
              unmount={false}
              key={`active-menu-${itemIndex}`}
              show={activeIndex === itemIndex}
              className="duration-300"
              testId={selectors.MenuStack.slideOverMenu(item.title)}
            >
              <MenuStackV2
                items={{ mainItems: item.children }}
                title={item.title}
                level={level + 1}
                onBack={() => {
                  setActiveIndex(-1);
                  resetScroll();
                }}
                resetScroll={resetScroll}
              />
            </SlidingTransition>
          ) : null
        )}
    </div>
  );
}
