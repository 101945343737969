/* eslint-disable import/prefer-default-export */
import * as React from "react";

interface QuantityInputProps {
  /** The disabled state of the input */
  disabled?: boolean;
  /** The id of the input element */
  id?: string;
  /** A label for the input element */
  label?: string;
  /** The id of a label for the input element */
  labelledBy?: string;
  /** The maximum allowed value */
  max?: number;
  /** The minimum allowed value */
  min?: number;
  /** The name of the input element */
  name?: string;
  /** The change event handler */
  onChange: (nextValue: number) => void;
  /** The size of increment and decrement */
  step?: number;
  /** The current value of the input */
  value: number;
}

type LocalValue = number | "";

function isNumeric(n: number | string | null | undefined) {
  return (
    typeof n === "number" ||
    (typeof n === "string" && !Number.isNaN(parseFloat(n)))
  );
}

function hasFocus(el: HTMLElement | null | undefined) {
  return (
    typeof window !== "undefined" &&
    document.hasFocus() &&
    el?.contains(document.activeElement)
  );
}

/**
 * The `QuantityInput` component is used to capture numeric input from a user. It renders an input
 * element as well as controls for incrementing and decrementing the component's value.
 *
 * Note that this is a controlled component: You must manage state in some parent component and
 * provide `QuantityInput` with both a value and change event handler.
 */
export function QuantityInput({
  disabled,
  id,
  label,
  labelledBy,
  max = Infinity,
  min = 0,
  name,
  onChange,
  step = 1,
  value: controlledValue,
}: QuantityInputProps) {
  const [localValue, setLocalValue] =
    React.useState<LocalValue>(controlledValue);

  const inputRef = React.useRef<HTMLInputElement>(null);

  // Keep a reference to the previous passed `value` so we can tell when it changes
  const memoValue = React.useRef<QuantityInputProps["value"]>(controlledValue);

  // Update local state when the passed `value` changes
  React.useEffect(() => {
    if (memoValue.current !== controlledValue) {
      memoValue.current = controlledValue;
      if (localValue !== controlledValue) {
        setLocalValue(controlledValue);
      }
    }
  }, [controlledValue, localValue, setLocalValue]);

  function isValidValue(value: LocalValue): value is number {
    return typeof value === "number" && value >= min && value <= max;
  }

  // Local state may be invalid while the input has focus, but we only call `onChange` when the
  // input is not focused and its value is valid.
  function setValue(value: LocalValue) {
    if (!disabled) {
      setLocalValue(value);

      if (
        !hasFocus(inputRef.current) &&
        isValidValue(value) &&
        value !== controlledValue
      ) {
        onChange(value);
      }
    }
  }

  function handleDecrement() {
    const next = Number(localValue) - step;
    if (isNumeric(localValue) && next >= min) {
      setValue(next);
    }
  }

  function handleIncrement() {
    const next = Number(localValue) + step;
    if (isNumeric(localValue) && next <= max) {
      setValue(next);
    }
  }

  function handleChange(event: React.ChangeEvent<HTMLInputElement>) {
    const { value } = event.currentTarget;
    setValue(isNumeric(value) ? Number(value) : "");
  }

  function handleBlur(event: React.FocusEvent<HTMLInputElement>) {
    const { value } = event.currentTarget;
    let next = Number(value);
    if (!isNumeric(value) || !isValidValue(next)) {
      next = controlledValue;
    }
    setValue(next);
  }

  function handleKeyDown(event: React.KeyboardEvent<HTMLInputElement>) {
    if (hasFocus(inputRef.current)) {
      if (event.key === "ArrowUp") {
        handleIncrement();
      } else if (event.key === "ArrowDown") {
        handleDecrement();
      } else if (event.key === "Home") {
        if (Number.isFinite(min)) {
          setValue(min);
        }
      } else if (event.key === "End") {
        if (Number.isFinite(max)) {
          setValue(max);
        }
      } else {
        return;
      }

      event.preventDefault();
    }
  }

  return (
    <div className="inline-flex items-center">
      <button
        aria-label="Decrease amount" // TODO i18n translate this
        className="flex items-center flex-shrink-0 px-2 text-xs disabled:text-flint disabled:cursor-default"
        data-testid="quantity-decrement-btn"
        disabled={disabled || !isNumeric(localValue) || localValue <= min}
        onClick={handleDecrement}
        tabIndex={-1}
        type="button"
      >
        {/* TODO replace - with 'minus' icon component */}
        <span aria-hidden className="select-none">
          -
        </span>
      </button>
      <input
        aria-invalid={!isValidValue(localValue) || undefined}
        aria-label={label}
        aria-labelledby={labelledBy}
        aria-valuemax={Number.isFinite(max) ? max : undefined}
        aria-valuemin={Number.isFinite(min) ? min : undefined}
        aria-valuenow={typeof localValue === "number" ? localValue : undefined}
        className="w-5 h-5 mx-0.5 text-xs text-center rounded-full appearance-none bg-gray-50 disabled:text-flint focus:outline-none focus:ring-2"
        disabled={disabled}
        id={id}
        inputMode="numeric"
        name={name}
        onBlur={handleBlur}
        onChange={handleChange}
        onKeyDown={handleKeyDown}
        pattern="[0-9]*"
        ref={inputRef}
        role="spinbutton"
        type="text"
        value={localValue}
      />
      <button
        aria-label="Increase amount" // TODO i18n translate this
        className="flex items-center flex-shrink-0 px-2 text-xs disabled:text-flint disabled:cursor-default"
        data-testid="quantity-increment-btn"
        disabled={disabled || !isNumeric(localValue) || localValue >= max}
        onClick={handleIncrement}
        tabIndex={-1}
        type="button"
      >
        {/* TODO replace + with 'plus' icon component */}
        <span aria-hidden className="select-none">
          +
        </span>
      </button>
    </div>
  );
}
