import * as React from "react";
import { Icon, ImageContext, LinkContext, PromoBadge } from "@components";
import classNames from "classnames";

import { cn } from "../../../../utils/tailwind-merge";
import type { LinkItemProps } from "../types";

export default function LinkItem({
  text,
  url,
  image,
  badge,
  type = "link",
  highlight = false,
  mobile = false,
  className,
  onClick,
}: LinkItemProps) {
  const LinkElement = React.useContext(LinkContext);
  const ImageElement = React.useContext(ImageContext);
  const isMobileCard = type === "card" && mobile;
  const shouldShowImage = type && ["article", "card"].includes(type);

  return (
    <LinkElement
      href={url}
      onClick={onClick}
      className={cn(
        "gap-4 gap-x-2 lg:gap-2 md:text-xs leading-tight",
        type === "card" && !mobile
          ? "flex flex-col"
          : "inline-flex items-center",
        {
          "relative items-center": badge,
          "text-slate": highlight,
          "py-5 w-full": mobile,
          "!py-2 underline lg:no-underline": mobile && shouldShowImage,
        },
        className
      )}
      variant={!mobile && type === "link" ? "underline" : undefined}
    >
      {image && shouldShowImage && (
        <div
          className={classNames(
            "relative",
            type === "card" && !mobile ? "aspect-[3/2]" : "aspect-square",
            {
              "h-6": type === "icon",
              "h-20": type === "article" || isMobileCard,
            }
          )}
        >
          <ImageElement
            src={image.src}
            className="w-full h-full"
            alt={image.alt}
          />
        </div>
      )}
      {type === "icon" && !image && <span className="block w-6" />}
      <span
        className={cn({
          "flex items-center justify-between": type === "card" && !mobile,
        })}
      >
        {text}
        {type === "card" && !mobile && <Icon name="arrow-right" />}
        {badge && <PromoBadge text={badge} bgColor="grey" className="ml-2" />}
      </span>
    </LinkElement>
  );
}
