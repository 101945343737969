import { Asset, Entry } from "contentful";

import { IShopifyProductFields } from "../../../../@types/generated/contentful";
import { formatProductBadges } from "../../util/badges";
import { isTruthy } from "../../util/isTruthy";
import { LocaleCode } from "../../util/locale";
import { getValidatedKeyValueArray } from "../../util/toKeyValue";
import { Product } from "../product/api";
import { formatSwatches, formatVariants } from "../product/utils";
import { formatProductOptions } from "../util/optionRendering";
import { getShopifyId } from "../util/shopifyID";

import {
  CatalogProduct,
  CatalogVariant,
  CatalogWithPointers,
  ProductCatalog,
} from "./types";

export function getProductPointers(
  contentEntries: Entry<IShopifyProductFields>[]
) {
  return contentEntries.reduce<Record<string, string>>(
    (dict, { fields: entry }) => {
      const productId = getShopifyId(entry.product);
      const mergedProductIds = entry.mergeWith?.map(getShopifyId) || [];
      mergedProductIds.forEach((id) => {
        if (dict[id]) {
          throw new Error(
            `Cannot merge a product under multiple parents. Conflict with ${dict[id]} and ${productId}`
          );
        }
      });

      const newKeys = Object.fromEntries(
        mergedProductIds.map((id) => [id, productId])
      );

      return {
        ...dict,
        ...newKeys,
      };
    },
    {}
  );
}

export function formatCatalogProducts(
  contentEntries: Entry<IShopifyProductFields>[],
  swatchAssets: Asset[],
  products: Product[]
) {
  const swatches = formatSwatches(swatchAssets);
  const productsById = Object.fromEntries(products.map((o) => [o.id, o]));
  return contentEntries
    .map(({ fields: content }) => {
      const productId = getShopifyId(content.product);
      const mergedProductIds =
        content.mergeWith?.map((id) => getShopifyId(id)) || [];
      const mergedProducts = [productId, ...mergedProductIds]
        .map((id) => productsById[id])
        .filter(isTruthy);
      if (!mergedProducts.length) return null;

      const validatedMappings = getValidatedKeyValueArray(
        content.mergeOptionMapping
      );

      const [product] = mergedProducts;
      const variants = formatVariants(
        mergedProducts,
        content.variants,
        content.mergeMode,
        validatedMappings
      ).map<CatalogVariant>(({ tooltip, ...v }) => ({
        ...v,
        tooltip,
        tooltipInStock: tooltip?.inStock ?? null,
      }));

      return {
        defaultVariant: getShopifyId(content.defaultVariant.fields.variantId),
        id: product.id,
        options: formatProductOptions(
          {
            content,
            products: mergedProducts,
            swatches,
          },
          content.mergeMode,
          validatedMappings
        ),
        badges: formatProductBadges(content.badges ?? []),
        productTitle: content.productTitle,
        slug: content.slug,
        variants: Object.fromEntries(variants.map((o) => [o.id, o])),
        productType: product.productType,
        shouldHidePrice: content.shouldHidePricing,
      };
    })
    .filter(isTruthy);
}

export function getURL(id: string, locale: LocaleCode) {
  return `/api/${locale}/catalog/${id}`;
}

export const makeCatalogProxy = (rawCatalog: CatalogWithPointers) =>
  new Proxy(rawCatalog, {
    get(target, id) {
      const productOrPointer = target[id as string];
      if (typeof productOrPointer === "string") return target[productOrPointer];
      return productOrPointer;
    },
  }) as ProductCatalog;

export function isCatalogProduct(product: unknown): product is CatalogProduct {
  return (
    !!product &&
    typeof product === "object" &&
    "id" in product &&
    "defaultVariant" in product &&
    "slug" in product &&
    "productTitle" in product &&
    "options" in product &&
    "variants" in product
  );
}

export function checkFeaturedImageAspectRatio(
  variants: CatalogVariant[],
  contentfulId?: string
) {
  const incorrectAspectRatioVariants = variants.filter((variant) => {
    const width = variant.cardImageFeatured?.width;
    const height = variant.cardImageFeatured?.height;
    if (!width || !height) return false;

    const aspectRatio = width / height;
    return Math.abs(aspectRatio - 2) > 0.25; // 1.75 to 2.25 ratios permitted
  });

  if (incorrectAspectRatioVariants.length) {
    const contentfulUrl =
      "https://app.contentful.com/spaces/t15gr55mpxw1/entries/";
    throw new Error(
      `Not all matching variants have a 2:1 aspect ratio for ${contentfulUrl}${contentfulId}: ${incorrectAspectRatioVariants
        .map((v) => `${contentfulUrl}${v.contentfulId}`)
        .join(", ")}`
    );
  }
}
