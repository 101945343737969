/* eslint-disable react/no-array-index-key */
/* eslint-disable import/prefer-default-export */
import React, { ReactNode } from "react";
import {
  CopyTextButton,
  Heading,
  Icon,
  ImageContext,
  LinkContext,
} from "@components";
import {
  CommonNode,
  documentToReactComponents,
  Options,
} from "@contentful/rich-text-react-renderer";
import {
  Block,
  BLOCKS,
  Document,
  Inline,
  INLINES,
  MARKS,
  Node,
  Text,
} from "@contentful/rich-text-types";
import classNames from "classnames";
import { Asset } from "contentful";

import { IconMap } from "../../../components/Icon/IconMap";
import { AnalyticsProvider } from "../../hooks/useAnalytics";
import { isTruthy } from "../../util";
import { CTA } from "../CTA";
import { EmailSignupForm } from "../EmailSignupForm";
import { ModalContent } from "../ModalContent";
import { SpacerSection } from "../SpacerSection";

import selectors from "./selectors";

const { useContext } = React;

interface RichTextProps {
  /** Rich text object received from Contentful API */
  richTextResponse: Document;

  /** Custom render options for the placement */
  customOptions?: Options["renderNode"];
}

export const getIdAndFields = (node: Node) => {
  const {
    data: {
      target: {
        sys: {
          id: contentfulId,
          contentType: {
            sys: { id },
          },
        },
      },
    },
  } = node;

  const {
    data: {
      target: { fields },
    },
  } = node;

  return { id, contentfulId, fields };
};

function isEmptyTextNode(node: CommonNode | Node) {
  return node.nodeType === "text" && "value" in node && !node.value.trim();
}

export function isCtaButton(node: CommonNode | Node) {
  return node?.data?.target?.sys?.contentType?.sys?.id === "ctaButton";
}

function isCopyToClipboard(node: CommonNode | Node) {
  return node?.data?.target?.sys?.contentType?.sys?.id === "copyToClipboard";
}

function isInlineCtaButton(node: CommonNode | Node) {
  return isCtaButton(node) && node.nodeType === INLINES.EMBEDDED_ENTRY;
}

function isModalContent(node: CommonNode | Node) {
  return node?.data.target?.sys?.contentType?.sys?.id === "modalContent";
}

function isEmailSignupSection(node: CommonNode | Node) {
  return node?.data.target?.sys?.contentType?.sys?.id === "emailSignupSection";
}

export function isSpacerSection(node: CommonNode | Node) {
  return node?.data.target?.sys?.contentType?.sys?.id === "spacerSection";
}

/** Standard HR used across the site */
export function HR({ className }: { className?: string }) {
  return (
    <hr
      className={classNames(
        "w-20 mt-5 mb-12 ml-1 border-b-2 border-gray-800 lg:mb-16",
        className
      )}
    />
  );
}

const baseOptions: Options = {
  renderNode: {
    [BLOCKS.HEADING_1]: (_node, children) => (
      <Heading as="h1" data-testid={selectors.h1}>
        {children}
      </Heading>
    ),
    [BLOCKS.HEADING_2]: (_node, children) => (
      <Heading as="h2" data-testid={selectors.h2}>
        {children}
      </Heading>
    ),
    [BLOCKS.HEADING_3]: (_node, children) => (
      <Heading as="h3" data-testid={selectors.h3}>
        {children}
      </Heading>
    ),
    [BLOCKS.HEADING_4]: (_node, children) => (
      <Heading as="h4" data-testid={selectors.h4}>
        {children}
      </Heading>
    ),
    [BLOCKS.HEADING_5]: (_node, children) => (
      <Heading as="h5" data-testid={selectors.h5}>
        {children}
      </Heading>
    ),
    [BLOCKS.HEADING_6]: (_node, children) => (
      <Heading as="h6" fontFamily="sans" data-testid={selectors.h6}>
        {children}
      </Heading>
    ),

    [BLOCKS.EMBEDDED_ASSET]: ({ data: { target } }, children) => {
      const {
        fields: {
          file: {
            url,
            contentType,
            details: { image },
          },
        },
      } = target as Asset;
      const ImageElement = React.useContext(ImageContext);

      // TODO: extend to handle other embedded asset types
      if (!contentType.startsWith("image")) {
        return children;
      }

      return (
        <div className="block mb-6">
          <ImageElement
            src={url}
            height={image?.height}
            width={image?.width}
            data-testid={selectors.img}
          />
        </div>
      );
    },
    [BLOCKS.PARAGRAPH]: ({ content }, children) => {
      // add flex to paragraphs that only contain inline CTA buttons
      const filteredChildren = content.filter((o) => !isEmptyTextNode(o));
      if (
        filteredChildren.length > 0 &&
        filteredChildren.every(isInlineCtaButton)
      ) {
        return (
          <p
            className="flex flex-wrap items-center justify-center md:justify-start gap-x-6 gap-y-4"
            data-testid={selectors.p}
          >
            {children}
          </p>
        );
      }
      return <p data-testid={selectors.p}>{children}</p>;
    },
    [BLOCKS.UL_LIST]: (_node, children) => (
      <ul className="pl-8 list-disc">{children}</ul>
    ),
    [BLOCKS.OL_LIST]: (_node, children) => (
      <ol className="styled-ol">{children}</ol>
    ),
    [BLOCKS.LIST_ITEM]: (_node, children) => <li>{children}</li>,
    [BLOCKS.HR]: () => <HR />,

    [INLINES.HYPERLINK]: (node, children) => {
      const {
        data: { uri },
        content,
      } = node;

      const LinkElement = useContext(LinkContext);

      const contentMarks = content
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        .map((i: any) => i.marks)
        .flat()
        .map((i) => i.type);

      const hasUnderline = contentMarks.includes("underline");

      return (
        <LinkElement
          href={uri}
          variant={hasUnderline ? "link" : "underline"}
          data-testid={selectors.hyperlink}
          className={hasUnderline ? "hover:opacity-[0.85]" : undefined}
        >
          {children}
        </LinkElement>
      );
    },
    [INLINES.EMBEDDED_ENTRY]: (node: Node) => {
      if (isCopyToClipboard(node)) {
        const {
          data: {
            target: {
              fields: { successText, text, textToCopyToClipboard },
            },
          },
        } = node;
        return (
          <CopyTextButton
            stringToCopy={textToCopyToClipboard}
            successCopyText={successText || text}
            className="font-bold"
          >
            {text}
          </CopyTextButton>
        );
      }

      if (isCtaButton(node)) {
        const {
          fields: { text, url, variant: rawVariant, size, arrowIcon, name },
          contentfulId: id,
          id: type,
        } = getIdAndFields(node);
        const variant =
          rawVariant === "underline" ? "button-underline" : rawVariant;

        return (
          <AnalyticsProvider object={{ name, id, type }}>
            <CTA url={url} variant={variant} size={size} arrowIcon={arrowIcon}>
              {text}
            </CTA>
          </AnalyticsProvider>
        );
      }

      return null;
    },
    [INLINES.ENTRY_HYPERLINK]: (node: Node) => {
      if (isCtaButton(node)) {
        const {
          fields: { text, url, variant, size, arrowIcon, name },
          contentfulId: id,
          id: type,
        } = getIdAndFields(node);
        return (
          <AnalyticsProvider object={{ name, id, type }}>
            <CTA url={url} variant={variant} size={size} arrowIcon={arrowIcon}>
              {text}
            </CTA>
          </AnalyticsProvider>
        );
      }
      return null;
    },
  },
  renderMark: {
    [MARKS.BOLD]: (text) => <span className="font-bold">{text}</span>,
  },
  renderText: (text) => {
    let copyOfText = `${text}`;
    if (copyOfText.includes("<copyright>")) {
      // TODO: i18n
      copyOfText = copyOfText.replace(
        "<copyright>",
        `© ${new Date().getFullYear()} THUMA Inc. All Rights Reserved.`
      );
    }
    // icons marked by the syntax :icon-name:, are matched and replaced in the text with icons defined in the project
    const iconSearchRegex = /(:\w*:)/g;

    function resolveIcon(
      lookup: string,
      innerIndex: number,
      outerIndex: number
    ) {
      const matched = iconSearchRegex.test(lookup);
      if (matched && lookup) {
        const sanitizedName = lookup.replace(/:/g, "");
        if (IconMap[sanitizedName]) {
          return (
            <Icon
              key={`${sanitizedName}-${outerIndex}-${innerIndex}`}
              name={sanitizedName}
              className="px-1"
            />
          );
        }
      }
      return lookup;
    }

    return copyOfText.split("\n").reduce(
      // @ts-expect-error this breaks text properly, ts just doesn't like it
      (children, textSegment, index) => {
        const splitOnIcons = textSegment.split(iconSearchRegex);
        const textWithIcons = splitOnIcons.map((value, innerIndex) =>
          resolveIcon(value, innerIndex, index)
        );

        const built = [
          ...children,
          index > 0 && <br key={index} />,
          textWithIcons,
        ];

        return built.flat();
      },
      []
    );
  },
};

enum CsvTags {
  Opening = "<csv>",
  Closing = "</csv>",
}

const isCsvData = (content: string) => {
  const trimmedContent = content.trim();
  return (
    trimmedContent.startsWith(CsvTags.Opening) &&
    trimmedContent.endsWith(CsvTags.Closing)
  );
};

const csvToTable = (csvText: string): React.ReactNode => {
  const [headerRow, ...bodyRows] = csvText
    .trim()
    .replace(CsvTags.Opening, "")
    .replace(CsvTags.Closing, "") // remove CSV tags
    .split("\n") // split rows at line breaks
    .filter(Boolean) // remove empty lines
    .map((row) => row.split(",")); // split columns at commas

  return (
    <div className="max-w-full overflow-x-auto">
      <table
        className="max-w-full table-fixed"
        style={{ width: `calc(${headerRow.length} * 6rem)` }}
      >
        <thead>
          <tr>
            {headerRow.map((column, columnIndex) => (
              <th
                key={`header-column-${columnIndex}`}
                className={classNames("font-normal leading-none pb-5 w-24", {
                  "sticky left-0 text-left bg-white": columnIndex === 0,
                  "text-center": columnIndex !== 0,
                })}
              >
                {column}
              </th>
            ))}
          </tr>
        </thead>
        <tbody>
          {bodyRows.map((columns, rowIndex) => (
            <tr key={`body-row-${rowIndex}`}>
              {columns.map((column, columnIndex) => (
                <td
                  key={`body-row-${rowIndex}-column-${columnIndex}`}
                  className={classNames(
                    "leading-none py-5 w-24 border-charcoal/60",
                    {
                      "sticky left-0 text-left bg-white": columnIndex === 0,
                      "text-center": columnIndex !== 0,
                      "border-r": columnIndex < columns.length - 1,
                    }
                  )}
                >
                  {column}
                </td>
              ))}
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
};

export function isThereText(values: Array<Block | Inline | Text>): boolean {
  if (values.length === 0) return false;
  const textValues = values.filter((v) => v.nodeType === "text") as Text[];
  if (textValues.some((v) => v.value.trim().length > 0)) return true;
  return (values.filter((v) => v.nodeType !== "text") as Array<Block | Inline>)
    .map((v) => isThereText(v.content))
    .some((v) => v);
}

const flexLayoutOptions: Options = {
  ...baseOptions,
  renderNode: {
    ...baseOptions.renderNode,
    [BLOCKS.TABLE_HEADER_CELL]: ({ content }, children) =>
      isThereText(content) ? (
        <div className="inline-block mb-4 text-charcoal">{children}</div>
      ) : null,
    [BLOCKS.TABLE_CELL]: (_node, children) => children,
    [BLOCKS.PARAGRAPH]: ({ content }, children) => {
      // this ensures nested tables are not rendered within a <p>
      const hasCsvData = content.some(
        (node) => node.nodeType === "text" && isCsvData(node.value)
      );
      if (hasCsvData) {
        return children;
      }

      if (!isThereText(content)) return null;
      return <p>{children}</p>;
    },
    [INLINES.HYPERLINK]: (node, children) => {
      const {
        data: { uri },
      } = node;

      const LinkFromContext = useContext(LinkContext);

      return (
        <LinkFromContext
          variant="underline"
          href={uri}
          data-testid={selectors.hyperlink}
        >
          {children}
        </LinkFromContext>
      );
    },
  },
  renderText: (text) => {
    // render CSV content as table
    if (isCsvData(text)) {
      return csvToTable(text);
    }

    // @ts-expect-error renderText is not undefined
    return baseOptions.renderText(text);
  },
};

const tableOptions: Options = {
  ...baseOptions,
  renderNode: {
    ...baseOptions.renderNode,
    [BLOCKS.TABLE_ROW]: (node, children) => {
      type WithValue = { value: string };
      type WithContent = { content: (WithValue | { content: WithValue[] })[] };

      const { content } = node;
      const cellCount = content.length;
      let {
        content: [cell],
      } = node as unknown as WithContent;
      while ("content" in cell) [cell] = cell.content;
      const header = cell.value;

      return (
        <>
          {header && (
            <tr className="text-center lg:hidden bg-dune">
              <td />
              <td colSpan={cellCount - 1}>{header}</td>
            </tr>
          )}
          <tr>{children}</tr>
        </>
      );
    },
  },
};

const renderOptions: Options = {
  ...baseOptions,
  renderNode: {
    ...baseOptions.renderNode,
    [BLOCKS.TABLE]: ({ content: rows }, children) => {
      const hasHeaderRow = rows.some((r) =>
        // @ts-expect-error content array is not typed accurately in type-def
        r.content.some((c) => c.nodeType === BLOCKS.TABLE_HEADER_CELL)
      );
      if (!hasHeaderRow) {
        return (
          <table className="custom-table">
            {documentToReactComponents(
              { nodeType: BLOCKS.DOCUMENT, content: rows } as Document,
              tableOptions
            )}
          </table>
        );
      }

      if (rows.length !== 2) {
        // TODO: Custom table rendering (if/when implemented) goes here
        return (
          <table>
            <tbody>{children}</tbody>
          </table>
        );
      }

      // @ts-expect-error the type-def doesn't cover deeply nested content
      const rowContent = [rows[0].content, rows[1].content] as typeof rows[];
      const formattedContent = rowContent.map((cells) =>
        cells.map((cell) =>
          documentToReactComponents(
            { nodeType: BLOCKS.DOCUMENT, content: [cell] } as Document,
            flexLayoutOptions
          )
        )
      );
      const [titles, bodies] = formattedContent;
      const groups = titles.map((title, index) => {
        const body = bodies[index] as ReactNode[];
        const typedTitle = title as ReactNode[];

        const isThereABody =
          body.length && body.flatMap((b) => b).filter(isTruthy).length !== 0;
        const isThereATitle =
          typedTitle.length && typedTitle.filter(isTruthy).length !== 0;

        return (
          <div
            key={index}
            className={classNames(
              "w-full pr-4 lg:max-w-sm text-slate lg:flex-1",
              {
                "empty:hidden md:empty:block": !isThereABody && !isThereATitle,
              }
            )}
          >
            {title}
            {body}
          </div>
        );
      });

      return (
        <div className="flex flex-wrap justify-between w-full mb-8 lg:mb-16 gap-x-8 gap-y-10">
          {groups}
        </div>
      );
    },

    // FIX: Since this can be a descendant of a p tag this cannot be a div
    // see "content with an embedded image asset" for an example of where this happens
    [BLOCKS.EMBEDDED_ENTRY]: (node: Node) => {
      if (isSpacerSection(node)) {
        const { size } = getIdAndFields(node).fields;
        return <SpacerSection size={size} renderAs="inline" />;
      }
      if (isEmailSignupSection(node)) {
        const {
          placeholderText,
          mobilePlaceholderText,
          errorText,
          mobileErrorText,
          successText,
          mobileSuccessText,
          subscriberSource,
          klaviyoSubOptions,
        } = getIdAndFields(node).fields;
        return (
          <EmailSignupForm
            placeholderText={placeholderText}
            mobilePlaceholderText={mobilePlaceholderText}
            successText={successText}
            mobileSuccessText={mobileSuccessText}
            errorText={errorText}
            mobileErrorText={mobileErrorText}
            subscriberSource={subscriberSource}
            klaviyoSubOptions={klaviyoSubOptions}
            renderAs="block"
          />
        );
      }

      if (isCtaButton(node)) {
        const {
          fields: { text, url, variant, size, arrowIcon, name },
          contentfulId: id,
          id: type,
        } = getIdAndFields(node);
        return (
          <div className="my-7" data-testid={selectors.entryLink}>
            <AnalyticsProvider object={{ name, id, type }}>
              <CTA
                url={url}
                variant={variant}
                size={size}
                arrowIcon={arrowIcon}
              >
                {text}
              </CTA>
            </AnalyticsProvider>
          </div>
        );
      }

      return null;
    },

    [INLINES.EMBEDDED_ENTRY]: (node: Node, children) => {
      const baseOptionRenderMethod =
        baseOptions.renderNode?.[INLINES.EMBEDDED_ENTRY];
      // @ts-expect-error node types don't match in type def but they're identical
      const baseOptionContent = baseOptionRenderMethod?.(node, children);
      if (baseOptionContent) return baseOptionContent;

      if (isModalContent(node)) {
        const {
          fields: { name, content, toggleButtonText, toggleButtonVariant },
        } = getIdAndFields(node);

        return (
          <ModalContent
            name={name}
            buttonText={toggleButtonText}
            buttonVariant={toggleButtonVariant}
          >
            {documentToReactComponents(content, renderOptions)}
          </ModalContent>
        );
      }

      return null;
    },
  },
};

/**
A Rich Text renderer that renders rich text received from Contentful
 */
export function RichText({
  richTextResponse,
  customOptions = {},
}: RichTextProps) {
  const options: Options = {
    ...renderOptions,
    renderNode: {
      ...renderOptions.renderNode,
      ...customOptions, // add in custom node options if provided
    },
  };

  return <>{documentToReactComponents(richTextResponse, options)}</>;
}
