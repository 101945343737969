import React from "react";

import { CardV2 } from "../../../../components";
import { useAnalyticsHandler } from "../hooks/useAnalyticsHandler";
import { CardType } from "../types";

interface CardV2SaddleProps {
  content: CardType;
}

export function CardV2Saddle({
  content: {
    fields: {
      title,
      image: {
        fields: {
          file: {
            url,
            details: { image },
          },
          title: imageTitle,
          description: alt,
        },
      },
      href,
      richBody,
      badge,
      underline,
    },
  },
}: CardV2SaddleProps) {
  const onClick = useAnalyticsHandler();
  return (
    <CardV2
      title={title}
      image={{
        src: url,
        alt: alt || imageTitle,
        height: image?.height,
        width: image?.width,
      }}
      href={href}
      body={richBody}
      badgeText={badge?.fields.text}
      underline={underline}
      clickHandler={onClick}
    />
  );
}
